<template >
  <div class="index-head-box" style="position: relative;" :style="{ background: '#7d6644 url(' + (info.webImg || '') + ') ' }">
    <div class="bg-black">
      <span class="head-icon" :style="{ background: 'url(' + (info.webImg || '') + ')' }">
        <i class="h-i-bg"></i>
      </span>
      <span class="head-name"
        >{{ info.trueName }}
        <i
          class="level-icon"
          :style="{ background: 'url(' + level + ') no-repeat center center', backgroundSize: 'cover' }"
        ></i>
      </span>
      <ul class="head-info">
        <li>非遗名录：{{ info.fintangibleDir }}</li>
        <li>非遗类型：{{ info.fintangibleType }}</li>
        <!-- <li>级别：{{ info.inheritorLevel }}</li> -->
      </ul>
      <div class="head-btn-box">
        <el-button
          v-if="!hideOpt && this.$store.state.username"
          class="btn-click"
          :class="active"
          @click="handleOptClick"
          >{{ text }}</el-button
		  >	
		<div
		v-else
          class="btn-click"
		  style="text-align: center;background-color: #666666;"
		   @mouseover="styleShow('1')"
		   @mouseleave="styleNone('1')"
          >加关注</div
        >
		<div disabled="true" :style="style" style="
		position: absolute;
		left: 260px;
		bottom: 20px;
		background-color: #FFFFFF;
		padding: 10px 10px 10px 10px;
		font-size: 10px;
		border-radius: 10px;
		">
			该传承人无绑定账号，无法关注
		</div>
		
		<div disabled="true" :style="style2" style="
		position: absolute;
		right: 260px;
		bottom: 20px;
		background-color: #FFFFFF;
		padding: 10px 10px 10px 10px;
		font-size: 10px;
		border-radius: 10px;
		">
			该传承人无绑定账号，无法私信
		</div>
        <div class="head-fance-box">
          <ul>
            <li>
              <span class="num">{{ dynamicNum }}</span>
              <span class="name">动态</span>
            </li>
            <li>
              <span class="num">{{ fanNum }}</span>
              <span class="name">粉丝</span>
            </li>
            <li>
              <span class="num">{{ followNum }}</span>
              <span class="name" >关注</span>
            </li>
          </ul>
        </div>
        
		<div v-if="hideOpt" class="btn-click" style="text-align: center;background-color: #666666;"
		@mouseover="styleShow('2')"
		@mouseleave="styleNone('2')"
		
		>	
		  发私信
		</div>
		<el-button v-else class="btn-click" @click="toSendMessage">{{
		  text1
		}}</el-button>
      </div>
    </div>

    <pop-message ref="popMessage" :config="configs" :friendId="info.memberId"></pop-message>
    <pop-news ref="popNews"></pop-news>
  </div>
</template>

<script>
import PopMessage from './popMessage.vue'
import popNews from '../components/popNews'

export default {
  props: {
    hideOpt: {
      type: Boolean,
      default: false
    },
    optType: {
      type: String,
      default: 'follow'
    },
    text: {
      type: String,
      default: '加关注'
    },
    text1: {
      type: String,
      default: '发私信'
    },
    active: {
      type: String,
      default: ''
    },
    isFollow: {
      type: [Number, Boolean],
      default: 0
    },
    info: {
      type: Object,
      default: () => {
        return {
          webImg: ''
        }
      }
    },
    fanNum: {
      type: Number,
      default: 0
    },
    followNum: {
      type: Number,
      default: 0
    },
    dynamicNum: {
      type: Number,
      default: 0
    }
  },
  components: {
    PopMessage,
    popNews
  },
  data() {
    return {
      bgImg: require('../assets/images/people.png'),
      headImg: require('../assets/images/people.png'),
      level: require('../assets/images/level.png'),
      configs: {
        width: '800px',
        // title: '温馨提示',
        center: true
        // btnTxt: ['取消', '打开内层'],
      },
	  style:{
		 display: 'none',
	  },
	  style2:{
		  display:'none'
	  }
    }
  },
  methods: {
	  styleShow(num){
		  if(num ==1){
			  this.style={
			  	display: 'inline-block'
			  	
			  }  
		  }else if(num == 2){
			  this.style2={
				  display: 'inline-block'
			  }
		  }
		
	  },
	  styleNone(num){
		  if(num ==1){
		  	this.style={
		  	display: 'none'		  	
		  	 }  
		  }else if(num == 2){
		  	this.style2={
		   display: 'none'
		  	 }
		  }
	  },
    toSendMessage() {
		if (this.isFollow === 0) {
		  this.axios
		    .post('/api/portal/friend/auth/follow/add', { inheritorId: this.$route.query.contentId })
		    .then(res => {
		      /* this.$message.success('关注成功') */
		      this.$emit('update:isFollow', 1)
		      this.$emit('update:fanNum', this.fanNum + 1)
		    })
		}
      this.$refs.popMessage.open(cancel => {})
    },
    handleOptClick() {
      if (this.optType === 'follow') {
        if (this.isFollow === 0) {
          this.axios
            .post('/api/portal/friend/auth/follow/add', { inheritorId: this.$route.query.contentId })
            .then(res => {
              this.$message.success('关注成功')
              this.$emit('update:isFollow', 1)
              this.$emit('update:fanNum', this.fanNum + 1)
            })
        } else {
          this.axios
            .post('/api/portal/friend/auth/follow/remove', { inheritorId: this.$route.query.contentId })
            .then(res => {
              this.$message.success('取消关注成功')
              this.$emit('update:isFollow', 0)
              this.$emit('update:fanNum', this.fanNum - 1)
            })
        }
      } else if (this.optType === 'post') {
        this.$refs.popNews.open(cancel => {})
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.index-head-box {
  background-size: cover !important;
  background: center center no-repeat;
}
</style>
