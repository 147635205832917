<template>
  <div>
    <el-dialog
      :modal="true"
      :modal-append-to-body="true"
      :visible.sync="shows"
      :width="width"
      :height="height"
      :close-on-click-modal="close"
      :show-close="false"
      :close-on-press-escape="true"
      @close="closeDialog"
      center
    >
      <div class="pop-div">
        <div
          class="img-info"
          :style="{ background: 'url(' + infoImg + ') no-repeat center center', backgroundSize: 'cover' }"
        >
          <i class="tip-l"></i>
          <i class="tip-r"></i>
        </div>
        <div class="img-content-info">
          <div class="con-t">
            <div class="per-info">
              <span
                class="per-icon"
                :style="{ background: 'url(' + headicon + ') no-repeat center center', backgroundSize: 'cover' }"
              ></span>
              <div class="per-con">
                <h5>祝石梁</h5>
                <p>2020-10-13 12:25</p>
              </div>
            </div>
            <p class="per-intro">
              南平市建瓯人，1956年8月生，国家级非物质文化遗产扩展项目中幡（建瓯挑幡）第五批省级代表性传承人。
            </p>
            <div class="dynamic-view-box">
              <div class="dynamic-view">
                <i class="hand-i"></i>
                <span>223</span>
              </div>
              <div class="dynamic-view">
                <i class="view-i"></i>
                <span>3434</span>
              </div>
            </div>
          </div>
          <div class="photo-list-boxs">
            <div class="photo-tits">
              <span>相册列表</span>
              <span>2020年10月</span>
            </div>
            <ul class="photo-ul">
              <li
                v-for="(item, index) in imgList"
                :class="{ active: index == ins }"
                :key="item.index"
                @click="active(index)"
                :style="{ background: 'url(' + item.img + ') no-repeat center center', backgroundSize: 'cover' }"
              ></li>
            </ul>
          </div>
        </div>
      </div>
      <!-- <div slot="footer" class="dialog-footer">
            <el-button @click="quxiao()">取 消</el-button>
            <el-button type="primary" @click="sumbim()">确 定</el-button>
        </div> -->
    </el-dialog>
  </div>
</template>
<script>
export default {
  props: {
    shows: {
      type: Boolean,
      default: true
    },
    switchStyle: {
      type: Boolean,
      default: true
    },
    //   tittle: {
    //     type: String,
    //     default: '新增'
    //   },
    width: {
      type: String,
      default: '35%'
    },
    height: {
      type: String,
      default: '1000px'
    },
    close: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      showss: false,
      ins: 0,
      headicon: require('../../assets/images/people.png'),
      infoImg: require('../../assets/images/info-img.jpg'),
      imgList: [
        { img: require('../../assets/images/info-img.jpg') },
        { img: require('../../assets/images/info-img.jpg') },
        { img: require('../../assets/images/info-img.jpg') },
        { img: require('../../assets/images/info-img.jpg') },
        { img: require('../../assets/images/info-img.jpg') },
        { img: require('../../assets/images/info-img.jpg') },
        { img: require('../../assets/images/info-img.jpg') },
        { img: require('../../assets/images/info-img.jpg') },
        { img: require('../../assets/images/info-img.jpg') }
      ]
    }
  },
  methods: {
    sumbim() {
      // this.showss = false
      this.$emit('closepop')
    },
    quxiao() {
      // this.showss = false
      this.$emit('closepop')
    },
    closeDialog() {
      this.$emit('closepop')
    },
    active(num) {
      this.ins = num
    }
  }
}
</script>
<style lang="scss" scoped>
/deep/.el-dialog--center .el-dialog__body {
  padding: 0 !important;
}
/deep/.el-dialog__header {
  display: none !important;
}
/deep/.el-dialog {
  background: transparent !important;
}
</style>
