<template>
  <el-dialog
    top="20vh"
    class="el-dialog-cus"
    v-bind="attributes"
    :visible="visible"
    :before-close="beClose"
    append-to-body
    :close-on-click-modal="true"
    v-on="on"
  >
    <div class="pop-new-box">
      <div class="pop-new-bg">
        <h5 class="pop-new-tit">
          <span>发私信</span><el-link class="custon-close" @click="cancel"><i class="el-icon-close"></i></el-link>
        </h5>
        <el-input class="pop-new-input" type="textarea" placeholder="请输入私信内容" v-model="message"></el-input>
        <div class="pop-new-btn">
          <div class="pop-down-box">
            <!-- <el-popover placement="bottom" width="390" trigger="click">
              <el-upload action="#" list-type="picture-card" :auto-upload="false">
                <i slot="default" class="el-icon-plus"></i>
                <div slot="file" slot-scope="{ file }">
                  <img class="el-upload-list__item-thumbnail" :src="file.url" alt="" />
                  <span class="el-upload-list__item-actions">
                    <span class="el-upload-list__item-preview" @click="handlePictureCardPreview(file)">
                      <i class="el-icon-zoom-in"></i>
                    </span>
                    <span v-if="!disabled" class="el-upload-list__item-delete" @click="handleDownload(file)">
                      <i class="el-icon-download"></i>
                    </span>
                    <span v-if="!disabled" class="el-upload-list__item-delete" @click="handleRemove(file)">
                      <i class="el-icon-delete"></i>
                    </span>
                  </span>
                </div>
              </el-upload>
              <el-dialog :visible.sync="dialogVisible">
                <img width="100%" :src="dialogImageUrl" alt="" />
              </el-dialog>
              <el-button class="add-img-btn" slot="reference"><i class="add-img-i"></i>添加图片</el-button>
            </el-popover> -->

            <el-popover placement="bottom" width="390" trigger="click">
              <el-upload action="#" list-type="picture-card" :auto-upload="false">
                <i slot="default" class="el-icon-plus"></i>
                <div slot="file" slot-scope="{ file }">
                  <img class="el-upload-list__item-thumbnail" :src="file.url" alt="" />
                  <span class="el-upload-list__item-actions">
                    <span class="el-upload-list__item-preview" @click="handlePictureCardPreview(file)">
                      <i class="el-icon-zoom-in"></i>
                    </span>
                    <span v-if="!disabled" class="el-upload-list__item-delete" @click="handleDownload(file)">
                      <i class="el-icon-download"></i>
                    </span>
                    <span v-if="!disabled" class="el-upload-list__item-delete" @click="handleRemove(file)">
                      <i class="el-icon-delete"></i>
                    </span>
                  </span>
                </div>
              </el-upload>
              <el-dialog :visible.sync="dialogVisible">
                <img width="100%" :src="dialogImageUrl" alt="" />
              </el-dialog>
              <!-- <el-button class="add-img-btn" slot="reference"><i class="add-video-i"></i>添加视频</el-button> -->
            </el-popover>
          </div>
          <el-button class="fb-btns" @click="sendMessage">发布</el-button>
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    friendId: String,
    config: {
      type: Object,
      default: () => ({
        width: '420px',
        title: '提示',
        center: true,
        btnTxt: ['取消', '确定']
      })
    },
    autoClose: {
      type: Boolean,
      default: true
    },
    beforeClose: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    const { config } = this,
      { btnTxt } = config
    return {
      message: '',
      types: 'user',
      activeName: 'first',
      visible: false,
      attributes: config,
      btnTxt,
      on: this.getDialogEvents(),
      dialogImageUrl: '',
      dialogVisible: false,
      disabled: false
    }
  },
  methods: {
    sendMessage() {
      this.axios
        .post('/api//portal/chat/auth/save', {
          friendId: this.friendId,
          imgUrls: '',
          content: this.message,
          fromClientType: 1
        })
        .then(res => {
          this.$message.success('发送成功')
        })
      this.cancel()
    },
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          alert('submit!')
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    handleRemove(file) {
      console.log(file)
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url
      this.dialogVisible = true
    },
    handleDownload(file) {
      console.log(file)
    },

    open(ok) {
      this.ok = ok
      this.visible = true
      this.message = ''
    },
    cancel() {
      this.visible = false
    },
    confirm() {
      let cancel = () => this.cancel()
      this.ok(cancel)
      this.autoClose && cancel()
    },
    beClose(done) {
      done()
      this.beforeClose()
      this.cancel()
    },
    getDialogEvents() {
      let { close } = this.config || {},
        events = {}

      if (close && typeof close == 'function') {
        Object.assign(events, {
          close
        })
      }

      return events
    }
  }
}
</script>

<style lang="scss">
@import '~@/assets/scss/index';
.el-dialog__header {
  display: none;
}
.el-dialog--center .el-dialog__body {
  padding: 0 !important;
}
.el-dialog {
  margin-bottom: 0px !important;
  background: transparent !important;
}
.el-upload-list--picture-card .el-upload-list__item {
  overflow: hidden;
  background-color: #fff;
  border: 1px solid #c0ccda;
  border-radius: 0px;
  box-sizing: border-box;
  width: 90px !important;
  height: 90px !important;
  margin: 0px 10px 20px 10px !important;
  display: inline-block;
}
.el-upload--picture-card {
  background-color: #ffffff !important;
  border: 1px dashed #cccccc !important;
  border-radius: 0px;
  box-sizing: border-box;
  width: 90px !important;
  height: 90px !important;
  line-height: 90px !important;
  vertical-align: top;
}
.el-popover {
  padding: 20px 25px 10px 25px !important;
  box-sizing: border-box;
  margin-top: 35px !important;
}
.el-upload--picture-card:hover,
.el-upload:focus {
  border-color: #d4a76b;
  color: #d4a76b;
}
</style>
